const { buildSetCookieHeader } = require('./responses')

/**
 * Method to parse a supplied URL and return the client identifier, no matter where the client identifier is located in the URL.
 * Please note the differences between the return values:
 * If a client identifier is in the URL, the function will return the client identifier as a string.
 * If no client identifier can be found in a valid URL, the function will return an empty string.
 * If there was an error parsing the URL (e.g. the constructor was supplied with a non-url), the function will return undefined.
 * @param {string} urlString The URL from which to locate the client identifier.
 * @returns {string} clientIdentifier
 */
exports.getClientIdentifierFromUrl = (urlString) => {
  let clientIdentifier = ''

  try {
    const { hostname, pathname } = new URL(urlString)
    const subdomain = hostname.split('.')[0]
    const oldSingleDomain = hostname.startsWith('admin.') || hostname.startsWith('user.')

    if (subdomain === 'navexadmin') {
      // The Platforminator client identifier is always navex
      clientIdentifier = 'navex'
    } else if (oldSingleDomain) {
      // Old single domain URL format: admin.navexone.com/pesjtest01
      clientIdentifier = pathname.split('/')[1]
    } else {
      // New single domain format: pesjtest01.navexone.com/home
      clientIdentifier = subdomain
    }
  } catch (error) {
    clientIdentifier = undefined
  }
  return clientIdentifier
}

/**
 * Method to parse a supplied URL and return the platform role, no matter where it is located in the URL.
 * Please note the differences between the return values:
 * If a platform role is in the URL, the function will return the role as a string.
 * If no platform role can be found in a valid URL, the function will return an empty string.
 * If there was an error parsing the URL (e.g. the constructor was supplied with a non-url), the function will return undefined.
 * @param {string} urlString The URL from which to locate the platform role
 * @returns {string} platformRole
 */
exports.getPlatformRoleFromUrl = (urlString) => {
  let platformRole = ''

  try {
    const { hostname, pathname } = new URL(urlString)
    const subdomain = hostname.split('.')[0]
    const isNavexAdmin = subdomain === 'navexadmin'
    const isNonSingleDomain = hostname.startsWith('admin.') || hostname.startsWith('user.')
    const isSingleDomain = pathname.split('/')[1] === 'admin' || pathname.split('/')[1] === 'home'

    if (isNavexAdmin) {
      // Navex Admin is in the subdomain whether on non-single-domain or single-domain
      platformRole = 'navexadmin'
    } else if (isSingleDomain) {
      // Single-domain URL format: pesjtest01.navexone.com/home
      platformRole = pathname.split('/')[1] === 'home' ? 'user' : 'admin'
    } else if (isNonSingleDomain) {
      // Non-single-domain URL format: admin.navexone.com/pesjtest01
      platformRole = subdomain
    }
  } catch (error) {
    platformRole = undefined
  }
  return platformRole
}

const isSecFetchRequest = (req) => {
  // sometimes fetch will send other headers instead of origin (cypress uses fetch)
  const secFetchMode = req.headers['sec-fetch-mode']
  const secFetchSite = req.headers['sec-fetch-site']
  return secFetchMode === 'cors' && secFetchSite === 'same-origin'
}

const isUserAgentInternetExplorer11 = (req) => {
  // IE11 does not send an origin header for cors requests that cross sites within a trusted zone
  const userAgentHeader = req.headers['user-agent']
  return userAgentHeader.indexOf('Trident') > -1
}

exports.verifyExpectedOrigin = (flow, req) => {
  if (!req.headers['origin']) {
    if (isSecFetchRequest(req) || isUserAgentInternetExplorer11(req)) {
      const referer = req.headers['referer']
      const refererUrl = new URL(referer)
      const requestHost = flow.xForwardedHostHeaderValue || flow.hostHeaderValue
      if (refererUrl.host === requestHost) {
        return // because it's valid even though there is no origin header
      }
    }

    throw new Error(
      'Request is missing one or more of the headers `origin`, `sec-fetch-mode`, `sec-fetch-site`, or `referer`'
    )
  }

  const originHeaderValue = req.headers['origin']
  const originUrl = new URL(originHeaderValue)
  const originHost = originUrl.host
  const requestHost = flow.xForwardedHostHeaderValue || flow.hostHeaderValue
  if (originHost !== requestHost) {
    throw new Error(`Rejected request from unexpected origin '${originHeaderValue}'`)
  }
}

const buildErrorHtml = (config) => {
  const isConfigObject = typeof config === 'object' && config && true
  const heading = (isConfigObject && config.heading) || 'Authentication Error'
  const paragraph = (isConfigObject && config.message) || config
  let extra = ''
  if (isConfigObject && Array.isArray(config.extra)) {
    extra = config.extra.map((x) => `<p>${x}</p>`).join('\n')
  }
  const errorHtml = `<!DOCTYPE html>
  <html>
  <head>
    <title>${heading}</title>
    <style type="text/css">
      body {
        padding: 20px;
        margin: 0;
        font-family: arial;
        font-size: 1em;
      }
      h1 {
        margin-top: 0;
      }
    </style>
  </head>
  <body>
    <h1>${heading}</h1>
    <p>${paragraph}</p>
    ${extra}
  </body>
  </html>`

  return errorHtml
}

exports.buildErrorResponse = (flow) => {
  let { message, status, statusDescription } = flow.error
  status = status || 500
  statusDescription = statusDescription || 'Internal Server Error'

  const errorHtml = buildErrorHtml(message)
  const errorResponse = {
    body: errorHtml,
    bodyEncoding: 'text',
    headers: {},
    status,
    statusDescription
  }

  const { settings } = flow
  const cookies = {
    nonce: 'expire',
    nonceHmac: 'expire',
    pkce: 'expire',
    idToken: 'expire',
    sessionId: 'expire',
    csrfToken: 'expire'
  }
  const cookieHeader = buildSetCookieHeader(settings, cookies)
  errorResponse.headers = {
    ...errorResponse.headers,
    ...cookieHeader,
    ...settings.cloudFrontHeaders
  }

  return errorResponse
}
