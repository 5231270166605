import axios, { AxiosRequestConfig, AxiosStatic, Method } from 'axios'
import settings from '../core/settings'
import { routeEndsWith } from '../utils/routeUtils'
import { filterMenuItemsWithWipFlag, fixSidebarPaths } from '../utils/singleSpaUtils'
import { IManifest } from '../interfaces/IManifest'
import { IManifestResponse } from '../interfaces/IManifestResponse'
import { IGlobal } from '../interfaces/IGlobal'
import { IWindow } from '../interfaces/IWindow'
import { IMenuItem } from '../interfaces/IMenuItem'
import { isDevelopmentSite } from '../utils/devUtils'
import { IInvokeApiOptions } from '../interfaces/IInvokeApiOptions'
import { getPlatformRoleFromUrl } from 'navex'
import {
  axiosErrorHandler,
  clearSessionTimer,
  startSessionStalenessTimer,
  loadTokenResponse,
  validateApiInvocation
} from '../utils/apiUtils'
import { getMenuItems, setUrlsFromAsiToggle } from './globalApi/'

declare const window: IWindow
declare const Global: IGlobal

const applyOverrideToMenuItemsRecursively = (menuItems: IMenuItem[]) => {
  for (let menuItem of menuItems) {
    menuItem.url = !!menuItem.urlOverrideKey
      ? window.Global.Overrides.get(menuItem.urlOverrideKey) || menuItem.url
      : menuItem.url
    if (!!menuItem.items) {
      applyOverrideToMenuItemsRecursively(menuItem.items)
    }
  }
}

const getToken = async (auth0ToggleEnabled: boolean, asmToggleEnabled: boolean, checkToggle: boolean) => {
  const tokenResponse = await loadTokenResponse({
    withAccessToken: true,
    auth0ToggleEnabled,
    asmToggleEnabled,
    checkToggle
  })

  // Handle HTTP 401 response from retrieving token to correctly mean unauthorized,
  // and throw accordingly for the caller to handle.
  if (tokenResponse.status === 401) {
    Global.Log.warn('Unauthorized to retrieve token.')
    throw new Error('Unauthorized')
  }

  return tokenResponse.data.data.accessToken
}

export class GlobalApi {
  public manifestResponse: IManifestResponse = undefined
  public manifests: IManifest[] | undefined = undefined
  public menuItems: IMenuItem[] = undefined
  public baseUrls: any = undefined
  public axios: AxiosStatic = axios

  async invoke(options: any) {
    Global.Log.warn('Global.API.invoke() is being deprecated. Use Global.API.invokeApi() instead.')
    return await axios(options).catch(axiosErrorHandler)
  }

  async invokeApi(options: IInvokeApiOptions) {
    let auth0ToggleEnabled
    let asmToggleEnabled
    let ssuiToggleEnabled
    if (!options.checkToggle) {
      auth0ToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('AUTH0_CUTOVER', false)
      asmToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('Auth0_ASM_Cutover', false)
      ssuiToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('N1_Appshell1_SSUI', false)
    }

    validateApiInvocation(options, window.location)

    // If both apiName and baseUrl exist, use apiName before baseUrl
    const baseURL = options.apiName ? this.baseUrls[options.apiName] : options.baseURL
    if (typeof baseURL === 'undefined') {
      Global.Log.warn(
        `Global.API.invokeApi was invoked with apiName '${options.apiName}' which does not match any base URL.`
      )
    }

    let config: AxiosRequestConfig = {
      ...options,
      baseURL,
      headers: { ...options.headers }
    }

    // TODO: Determine whether we should first check that the hostname of the url matches the baseDomain
    const requireAuth = options.authRequired
    const checkToggle = options.checkToggle
    if (requireAuth) {
      clearSessionTimer()

      const token = await getToken(auth0ToggleEnabled, asmToggleEnabled, checkToggle)
      config.headers['Authorization'] = `Bearer ${token}`
    }

    // Add onto this table as more services are containerized
    const asiToggleTable = {
      'people-hub-api': { toggle: 'N1_CH_ASI_API', crossSaveRoutes: ['/admin/config.save'] }
    }

    if (asiToggleTable[options.apiName]) {
      const asiEnabled = await Global.LaunchDarkly.signedIn.booleanVariation(
        asiToggleTable[options.apiName].toggle,
        false
      )
      let crossSaveBaseUrl: string
      let newBaseUrl: string
      if (asiEnabled) {
        ({ baseUrl: newBaseUrl, crossSaveBaseUrl } = await setUrlsFromAsiToggle(asiEnabled, baseURL))
        config.baseURL = newBaseUrl
      }
      // TODO: Uncomment when new DB is ready
      // if (asiToggleTable[options.apiName].crossSaveRoutes.some((route: string) => config.url === (route))) {
      //   const crossSaveConfig = structuredClone(config)
      //   crossSaveConfig.baseURL = crossSaveBaseUrl
      //   axios(crossSaveConfig).catch(axiosErrorHandler)
      // }
    }

    const response = await axios(config).catch(axiosErrorHandler)

    // If checkToggle is true, we don't want to invoke startSessionStalenessTimer() because we don't have the correct LD toggle values yet.
    if (!checkToggle && !ssuiToggleEnabled && requireAuth) {
      await startSessionStalenessTimer(auth0ToggleEnabled, asmToggleEnabled)
    }

    return response
  }

  async get(apiName: string, path: string, config?: any) {
    Global.Log.warn('Global.API.get() is being deprecated. Use Global.API.invokeApi() instead.')
    const options: IInvokeApiOptions = {
      ...(<AxiosRequestConfig>(config || {})),
      authRequired: true,
      apiName,
      method: 'GET',
      url: path
    }
    return await this.invokeApi(options)
  }

  async post(apiName: string, path: string, data?: any, config?: any) {
    Global.Log.warn('Global.API.post() is being deprecated. Use Global.API.invokeApi() instead.')
    const options: IInvokeApiOptions = {
      ...(<AxiosRequestConfig>(config || {})),
      authRequired: true,
      apiName,
      method: 'POST',
      url: path,
      data
    }
    return await this.invokeApi(options)
  }

  async getExternal(url: string, config?: any) {
    Global.Log.warn('Global.API.getExternal() is being deprecated. Use Global.API.invokeApi() instead.')
    return await axios.get(url, config).catch(axiosErrorHandler)
  }

  async putExternal(url: string, data?: any, config?: any) {
    Global.Log.warn('Global.API.putExternal() is being deprecated. Use Global.API.invokeApi() instead.')
    return await axios.put(url, data, config).catch(axiosErrorHandler)
  }

  async postExternal(url: string, data?: any, config?: any) {
    Global.Log.warn('Global.API.postExternal() is being deprecated. Use Global.API.invokeApi() instead.')
    return await axios.post(url, data, config).catch(axiosErrorHandler)
  }

  // deprecated, renamed to getBaseUrlByApiName
  getBaseUri(apiName: string) {
    return this.getBaseUrlByApiName(apiName)
  }

  getBaseUrlByApiName(apiName: string) {
    return this.baseUrls[apiName]
  }

  async loadMenuItemsAndApiBaseUriList(checkToggle) {
    const params = this.getParams()

    const baseUri = window.Global.Overrides.get('Override_AppshellServiceUri') || settings.AppShellSvcBaseUri
    const response = await this.invokeApi({
      authRequired: false,
      checkToggle,
      method: 'GET',
      baseURL: baseUri,
      url: '/v1/getMenuItemsAndApiBaseUriList',
      params
    }).catch(axiosErrorHandler)

    this.manifestResponse = response.data.data
    const manifests: IManifest[] = response.data.data.api.items
    let menuItems = filterMenuItemsWithWipFlag(response.data.data.ui.items)

    if (shouldFixPaths()) {
      menuItems = fixSidebarPaths(menuItems)
    }

    const baseUrls: any = {}

    // Localhost overrides
    for (let manifest of manifests) {
      baseUrls[manifest.apiName] = !!manifest.urlOverrideKey
        ? (isDevelopmentSite() && window.Global.Overrides.get(manifest.urlOverrideKey)) || manifest.apiBaseUri
        : manifest.apiBaseUri
    }

    if (isDevelopmentSite()) {
      applyOverrideToMenuItemsRecursively(menuItems)
    }

    this.baseUrls = baseUrls
    this.menuItems = menuItems
  }

  async getIsFeatureToggleEnabled(featureKey: number) {
    const response = await this.invokeApi({
      authRequired: true,
      method: 'GET',
      apiName: 'AddEditUserApi',
      url: `/clientSettings/isFeatureToggleEnabled?data=${featureKey}&clientKey=${Global.Utils.getClientKey()}`
    }).catch(axiosErrorHandler)

    if (response && response.data && response.data.status === 'success') {
      if (response.data.data) {
        const featureToggleEnabled = response.data.data
        return featureToggleEnabled
      }
    }
    return false
  }

  getMenuItems() {
    return getMenuItems(this.menuItems)
  }

  getParams() {
    const role = getPlatformRoleFromUrl(window.location.href)
    if (routeEndsWith('/overrides')) {
      return {
        menu: 'overrides',
        area: '',
        lang: Global.Localization.getCurrentLocale()
      }
    } else if (role === 'user') {
      return {
        menu: 'user',
        area: '',
        lang: Global.Localization.getCurrentLocale()
      }
    } else if (role === 'admin') {
      return {
        menu: 'admin',
        area: '',
        lang: Global.Localization.getCurrentLocale()
      }
    } else if (role === 'navexadmin') {
      return {
        menu: 'navexadmin',
        area: '',
        lang: Global.Localization.getCurrentLocale()
      }
    } else {
      throw new Error('Invalid area')
    }
  }
}

const shouldFixPaths = () => {
  // TODO: Move this logic to the manifests
  const role = getPlatformRoleFromUrl(window.location.href)
  if (role === 'user' || role === 'admin' || role === 'navexadmin') {
    return true
  }
  return false
}
