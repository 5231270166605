import settings from '../core/settings'
import { triggerAppChange, unloadApplication } from 'single-spa'
import * as queryStringUtils from '../utils/queryStringUtils'
import { IGlobal } from '../interfaces/IGlobal'
import { buildBundleUri } from '../utils/bundleUtils'
import { wipFlagExistsInOverrides, wipFlagExistsInQueryString } from '../utils/wipFlagUtils'
import { getManifestAuthenticationType, isOverrides } from '../utils/routeUtils'
import { getPreloadedLaunchDarklyToggleState } from './globalLaunchDarkly'
import { loadTokenResponse, getCustomerInfo } from '../utils/apiUtils'
import { ICustomerInfoRequest, ICustomerInfoResponse } from '../interfaces/IInvokeApiOptions'
import { appShellRootUIName } from '../utils/constants'
import { getPlatformRoleFromUrl } from 'navex'
import { LDUserContext } from 'src/interfaces/IGlobalLaunchDarkly'

declare const Global: IGlobal

export class GlobalUtils {
  isAdminContainerReady = false
  historyObject = undefined
  titleOverrideCallback = undefined
  customerInfoCookieValues = {
    AdminClientKey: undefined,
    AdminTenantAlias: undefined,
    AdminTenantId: undefined
  }

  getAdminArea = () => {
    const role = getPlatformRoleFromUrl(window.location.href)
    if (role === 'navexadmin' || role === 'admin' || role === 'user') {
      return role
    }
    return window.location.pathname.split('/')[2]
  }

  getNavexAdminHost = () => {
    return settings.NavexAdminBaseUri
  }

  getCustomerAdminHost = () => {
    return settings.CustomerAdminBaseUri
  }

  // This is called by landingpage-admin-ui for showing the landing page inside
  // of the Live Preview iframe.
  getCmdHost = (clientKeyOverride) => {
    const clientIdentifer =
      clientKeyOverride || (this.tenantAliasToggleIsOn() ? this.getTenantAlias() : this.getClientKey())
    const isSingleDomainPtFlagOn = getPreloadedLaunchDarklyToggleState('SingleDomainPT')
    if (isSingleDomainPtFlagOn) {
      return this.makeTenantSpecific(settings.PlatformCmadUiBaseUriTemplate, clientIdentifer)
    } else {
      return this.makeTenantSpecific(settings.CmadUiWildcardUri, clientIdentifer)
    }
  }

  getEpimUiHost = (clientKeyOverride) => {
    const clientIdentifier =
      clientKeyOverride || (this.tenantAliasToggleIsOn() ? this.getTenantAlias() : this.getClientKey())
    return this.makeTenantSpecific(settings.EpimUiWildcardBaseUri, clientIdentifier)
  }

  getLandingPageHost = () => {
    const clientIdentifier = this.adminSettingsAliasToggleIsOn() ? this.getTenantAlias() : this.getClientKey()
    return this.makeTenantSpecific(settings.LandingPageWildcardUri, clientIdentifier)
  }

  getLandingPagePreviewHost = () => {
    const clientIdentifier = this.adminSettingsAliasToggleIsOn() ? this.getTenantAlias() : this.getClientKey()
    return this.makeTenantSpecific(settings.LandingPageIFrameWildcardUri, clientIdentifier)
  }

  getClientKey = () => {
    return this.customerInfoCookieValues.AdminClientKey || Global.CookieUtils.get('AdminClientKey')
  }

  getTenantAlias = () => {
    return this.customerInfoCookieValues.AdminTenantAlias || Global.CookieUtils.get('AdminTenantAlias')
  }

  getTenantId = () => {
    // Only get value from the cookie after full page reloads, after logging in, opening in a new tab, etc
    // Otherwise, if you always pull from the cookie then if the user has two different clients open in two different
    // tabs then saving a form in one tab can end up saving over top of client data in the other tab
    return this.customerInfoCookieValues.AdminTenantId || Global.CookieUtils.get('AdminTenantId')
  }

  getClientKeyForAuthN = () => {
    const authNType = getManifestAuthenticationType(Global.API.menuItems)
    switch (authNType) {
      case 'navex':
        return 'navex'
      case 'client':
        return this.getClientKey()
      default:
        return undefined
    }
  }

  getTenantAliasForAuthN = () => {
    const authNType = getManifestAuthenticationType(Global.API.menuItems)
    switch (authNType) {
      case 'navex':
        return 'navex'
      case 'client':
        return this.getTenantAlias()
      default:
        return undefined
    }
  }

  getBaseRoute = () => {
    // TODO: Move this logic to the manifests
    const role = getPlatformRoleFromUrl(window.location.href)
    const clientIdentifier = this.tenantAliasToggleIsOn() ? Global.Utils.getTenantAlias() : Global.Utils.getClientKey()

    const knownPathRoles = ['admin', 'home']
    const route = window.location.pathname
    const pathSegments = route.split('/')
    const pathRole = pathSegments[1]
    const pathPrefix = knownPathRoles.includes(pathRole) ? pathRole : clientIdentifier

    if (role === 'user') {
      const adminArea = Global.Utils.getAdminArea()
      const baseRoute = `/${pathPrefix}/${adminArea}`
      return baseRoute
    } else if (role === 'admin') {
      const baseRoute = `/${pathPrefix}`
      return baseRoute
    }
    return ''
  }

  getIsFeatureToggleEnabled = async (featureKey: number) => {
    return await Global.API.getIsFeatureToggleEnabled(featureKey)
  }

  getMenuItems = () => {
    return Global.API.getMenuItems()
  }

  getRootContainer = () => {
    return document.getElementById('root')
  }

  getRootModalContainer = () => {
    return document.getElementById('rootmodal')
  }

  getAdminContentContainerId = () => {
    return 'appshell-navigation-ui-content'
  }

  getAdminContentContainer = () => {
    const id = Global.Utils.getAdminContentContainerId()
    return document.getElementById(id)
  }

  triggerSingleSpaAppChange = () => {
    /** If a micro-ui does not need to show the loading spinner
     * (such as the home page which is static, or any app that
     * has already loaded and is being kept in memory) then it's
     * possible to get in a situation where one app has failed to clear
     * the loading spinner, and navigating to another app that has already
     * loaded would never clear it either leaving the spinner blocking
     * the content. This next line will prevent such situations by
     * resetting both the internal and content loading spinners before
     * proceeding with navigating to another application. When in such a
     * state and navigating to an app that has NOT loaded yet, this will
     * result in a blip of seeing the loading spinner flash. But this is
     * an acceptable outcome - the best fix would be to resolve the issue
     * in the app that is failing to hide the loading spinner.
     **/
    Global.UI.clearLoadingStates()
    triggerAppChange()
  }

  onNavigationUiRendered = () => {
    this.triggerSingleSpaAppChange()
  }

  unloadApplicationSingleSpa = (appName: string) => {
    unloadApplication(appName)
  }

  getBackToGatewayUrl = () => {
    const clientIdentifier = this.tenantAliasToggleIsOn() ? this.getTenantAlias() : this.getClientKey()
    return this.makeTenantSpecific(settings.GatewayUiBaseUriTemplate, clientIdentifier)
  }

  getDoormanBaseUrl = () => {
    return settings.DoormanBaseUrl
  }

  getNavexCdnBaseUri = () => {
    return settings.NavexCdnBaseUri
  }

  getCustomerManagerUiUrl = () => {
    return settings.CustomerManagerUiUrl
  }

  wipFlagExists = (wipFlagId: string) => {
    return wipFlagExistsInOverrides(wipFlagId) || wipFlagExistsInQueryString(wipFlagId)
  }

  setIsAdminContainerReady = (isLoaded) => {
    this.isAdminContainerReady = isLoaded
  }

  getIsAdminContainerReady = () => {
    return this.isAdminContainerReady
  }

  setHistoryObject = (historyObject) => {
    this.historyObject = historyObject
  }

  getHistoryObject = () => {
    return this.historyObject
  }

  /** Method to be used by appshell-navigation-ui to assign the setTitleOverride func the same way it does with setHistoryObj */
  setTitleOverrideCallback = (titleOverrideCallback: (text: string) => void) => {
    this.titleOverrideCallback = titleOverrideCallback
  }

  /** Method to use to call the setTitleOverride callback on the AppNavigationUI instance in appshell-navigation-ui
   * Use the `%PAGE_TITLE%` token in your text argument to extend the current page title.
   * e.g. When the pageTitle would normally be "Users", passing "%PAGE_TITLE%: Edit" would result in "Users: Edit"
   *
   * ⚠ **IMPORTANT** ⚠
   *
   * It is very important to call `clearTitleOverride()` in a useEffect's returned cleanup function,
   * otherwise any overrides set for one component could persist to other parts of the app.
   */
  setTitleOverride = (text: string) => {
    if (this.titleOverrideCallback) this.titleOverrideCallback(text)
  }

  /** Method to clear any existing titleOverrides created with `setTitleOverride`.
   *
   * ⚠ **IMPORTANT** ⚠
   *
   * It is very important to call this method in a useEffect's returned cleanup function,
   * otherwise any overrides set for one component could persist to other parts of the app.
   */
  clearTitleOverride = () => {
    if (this.titleOverrideCallback) this.titleOverrideCallback(undefined)
  }

  getQueryStringParameters = (url) => queryStringUtils.getQueryStringParameters(url)

  getQueryStringParameter = (url, paramName) => queryStringUtils.getQueryStringParameter(url, paramName)

  buildContentUri = (microFrontendName: string, contentPath: string) => {
    return buildBundleUri(microFrontendName, 'content', contentPath, true)
  }

  getCustomerInfoAndSetCookies = async (checkToggle) => {
    let request: ICustomerInfoRequest = {}
    if (this.getClientKey()) request['clientKey'] = this.getClientKey()
    if (this.getTenantAlias()) request['alias'] = this.getTenantAlias()

    if (!isOverrides(window.location.pathname) && Object.keys(request).length === 1) {
      // A valid request will only have either clientKey OR tenantAlias. If both are present, the cookies are already set and we don't need to hit the API again.
      Global.UI.showLoadingPanel(appShellRootUIName)

      try {
        const response: ICustomerInfoResponse = await getCustomerInfo(request, checkToggle)

        this.setCookie('AdminClientKey', response.clientKey)
        this.setCookie('AdminTenantAlias', response.alias)
        this.setCookie('AdminTenantId', response.tenantId)
      } catch (err) {
        Global.Log.error('ERROR: Unable to set cookies')
        // If it was a token HTTP 401 error,
        // re-throw the error to be caught by the caller.
        if (err.message === 'Unauthorized') {
          throw err;
        }
      }
    }
  }

  setCookie = (name: 'AdminClientKey' | 'AdminTenantAlias' | 'AdminTenantId', value: string) => {
    if (value !== undefined) {
      this.customerInfoCookieValues[name] = value
      const expires = undefined // Use expires=undefined to make it a session cookie. (See BES-1025)
      Global.CookieUtils.set(name, value, window.location.hostname, expires)
    }
  }

  clearCustomerInfoCookies = () => {
    Global.CookieUtils.remove('AdminClientKey', window.location.hostname)
    Global.CookieUtils.remove('AdminTenantAlias', window.location.hostname)
    Global.CookieUtils.remove('AdminTenantId', window.location.hostname)
    this.customerInfoCookieValues = {
      AdminClientKey: undefined,
      AdminTenantAlias: undefined,
      AdminTenantId: undefined
    }
  }

  /**
   * Returns the Launch Darkly Client Side ID for the current environment.
   */
  getLaunchDarklyClientId = () => {
    return settings.LaunchDarklyClientId
  }

  /**
   * Implementation following the spec outlined here:
   * [Implementing Release toggles using LD in your project](https://confluence.navexglobal.com/x/f4PkC)
   * See the **Clients/Customers** section
   */
  getLaunchDarklyUserObj = (key?: string): LDUserContext => {
    // Putting this code back because it was modified when we thought NEW clients would have null for their clientKey
    // Now we know that all clients even New ones will still have a clientKey - so until we migrate LaunchDarkly,
    // this needs to stay put.
    const contextKind = 'user'
    const launchDarklyUserId = key || this.getClientKey() || 'navex'
    const context: LDUserContext = {
      kind: contextKind,
      key: launchDarklyUserId,
      firstName: launchDarklyUserId,
      // Leaving custom in for now to be backwards compatible - Try to get this removed in the future
      clientKey: launchDarklyUserId
    }

    /*const user: LDUser = {
      key: launchDarklyUserId,
      firstName: launchDarklyUserId,
      // Leaving custom in for now to be backwards compatible - Try to get this removed in the future
      custom: { clientKey: launchDarklyUserId }
    }*/
    return context
  }

  getKeyCloakUrl = () => {
    const clientIdentifier = this.tenantAliasToggleIsOn() ? this.getTenantAliasForAuthN() : this.getClientKeyForAuthN()
    return this.makeTenantSpecific(settings.KeyCloakUrl, clientIdentifier)
  }

  getPlatformTopNavScriptUrl = () => {
    const platformTopNav = this.getMenuItems().find((x) => x.appName === 'PlatformTopNav')
    return (!!platformTopNav && platformTopNav.url) || ''
  }

  getPlatformAdminHelpUrl = async () => {
    return settings.PlatformAdminHelpUrl
  }

  getPathsThatDontRequireTenantSelection = () => {
    return Global.API.manifestResponse.ui.pathsThatDontRequireTenantSelection
  }

  tenantAliasToggleIsOn = () => {
    return getPreloadedLaunchDarklyToggleState('CIA_CM_TenantAlias')
  }

  adminSettingsAliasToggleIsOn = () => {
    return getPreloadedLaunchDarklyToggleState('CIA_AdminSettings_UseAlias')
  }

  customerInfoCookiesHaveBeenSet = () => {
    const cookiesHaveBeenSet =
      this.getClientKey() !== undefined && this.getTenantAlias() !== undefined && this.getTenantId() !== undefined
    return cookiesHaveBeenSet
  }

  /**
   * This method is only approved for use by appshell-navigation-ui and platform-landingpage-ui which embed PlatformTopNav and SessionStatusUI.
   * It will be deprecated eventually once PlatformTopNav and SessionStatusUI change to not get the token from the host app.
   *
   * Until this method gets deprecated, these are the only files authorized to use this method:
   * https://github.com/tnwinc/appshell-navigation-ui/blob/develop/src/components/PlatformTopNavContainer.tsx
   * https://github.com/tnwinc/appshell-navigation-ui/blob/develop/src/components/SessionStatusUIContainer.tsx
   * https://github.com/tnwinc/platform-landingpage-ui/blob/develop/src/components/PlatformTopNavContainer.tsx
   * https://github.com/tnwinc/platform-landingpage-ui/blob/develop/src/components/SessionStatusUIContainer.tsx
   *
   * Any other consumers of this method must be approved by Sequoia and Bespin.
   * @param skipActivityCheck If the request is not from user activity (e.g. periodically checking for tasks),
   * we should skip extending the session
   * @returns string
   */
  getBearerTokenToBeDeprecatedOnlyForTemporaryUseByAuthorizedConsumers = async (skipActivityCheck = false) => {
    const auth0ToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('AUTH0_CUTOVER')
    const asmToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('Auth0_ASM_Cutover')

    const tokenResponse = await loadTokenResponse({
      withAccessToken: true,
      auth0ToggleEnabled,
      asmToggleEnabled,
      checkToggle: false,
      skipActivityCheck
    })
    return tokenResponse.data.data.accessToken
  }

  makeTenantSpecific = (domain: string, tenantAlias: string): string => {
    return domain?.replaceAll('*', tenantAlias)
  }
}
