const { parse } = require('cookie')

const extractCookiesFromHeaders = (headers) => {

  if (!headers["cookie"]) {
    return {}
  }

  const cookies = parse(headers['cookie'])
  return cookies
}

exports.parseAndExtractCookies = (flow, req) => {
  const { settings: { cookies: cookieSettings } } = flow
  const cookies = extractCookiesFromHeaders(req.headers)
  if (!cookies) {
    return {}
  }

  return {
    nonce: cookies[cookieSettings.nonce.name],
    nonceHmac: cookies[cookieSettings.nonceHmac.name],
    pkce: cookies[cookieSettings.pkce.name],
    idToken: cookies[cookieSettings.idToken.name],
    sessionId: cookies[cookieSettings.sessionId.name],
    csrfToken: cookies[cookieSettings.csrfToken.name],
  }
}

exports.expireCookie = (cookie) => {
  const cookieParts = cookie
    .split(';')
    .map((part) => part.trim())
    .filter((part) => !part.toLowerCase().startsWith('max-age'))
    .filter((part) => !part.toLowerCase().startsWith('expires'))
  const expires = `Expires=${new Date(0).toUTCString()}`
  const [, ...settings] = cookieParts; // first part is the cookie value, which we'll clear
  return ['', ...settings, expires].join('; ')
}

const isHttpOnly = (cookieName) => {
  switch (cookieName) {
    case 'CSRF_TOKEN':
    case 'AdminTenantId':
    case 'AdminTenantAlias':
    case 'AdminClientKey':
      return false;
    default:
      return true;
  }
}

const parseSetCookie = (cookie) => {
  const cookieParts =
    cookie
      .split(';')
      .map(part => part.trim());

  const cookieNameValue = cookieParts[0].split('=');
  const name = cookieNameValue[0];
  const value = cookieNameValue[1];

  let path = '/';
  let sameSite = undefined;
  let expires = undefined;
  cookieParts.slice(1).forEach(part => {
    const optionNameValue = part.split('=');
    switch (optionNameValue[0]) {
      case 'Path':
        path = optionNameValue[1];
        break;
      case 'SameSite':
        sameSite = optionNameValue[1];
        break;
      case 'Expires':
        expires = new Date(optionNameValue[1]);
        break;
    }
  });

  return {
    name,
    value,
    options: {
      path,
      httpOnly: isHttpOnly(name), // CSRF_TOKEN cookie is read by appshell v1
      secure: true,
      sameSite,
      expires,
    },
  };
}

exports.setCookiesFromHeaders = (res, headers) => {
  if (!headers) {
    return;
  }
  for (const [headerKey, headerValue] of Object.entries(headers)) {
    if (headerKey === 'set-cookie') {
      headerValue.forEach(cookie => {
        const { name, value, options } = parseSetCookie(cookie.value);
        res.cookie(name, value, options);
      });
      // remove set-cookie header so it will not override the sent cookies
      delete headers['set-cookie']
    }
  }
}
